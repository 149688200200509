import { Document } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Helmet } from 'react-helmet';
import { About, Hero, Layout, Services, Team, Testimonials } from '../components/atoms';
import { GatsbyRichText } from '../components/contentful-rich-text';

type PageQuery = {
  site: {
    siteMetadata: {
      title: string
    }
  };
  contentfulWebsitePage: {
    slug: string
    title: string
    body: {
      json: Document
    }
  }  
}

export const RootIndex = (props: {location: object, data: PageQuery}) => {
    const siteTitle = get(props, 'data.site.siteMetadata.title');
    console.log(props.location);
    return (
        <Layout location={props.location}>
            <Helmet title={siteTitle} />
            <Hero />
            <About>
                <GatsbyRichText body={props.data.contentfulWebsitePage.body.json}/>
            </About>
            <Services />
            <Team />
            <Testimonials />
        </Layout>
    );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    contentfulWebsitePage(title: {eq: "Home"}) {
      slug
      title
      body {
        json
      }
    }    
  }
`;
